import * as React from "react"
import Layout from "../components/layout"
import IndexPage from "../components/pageComponents/index"
import messages from "../i18n/it"

const IndexPageIt = ({ location }) => (
  <Layout location={location} messages={messages}>
    <IndexPage langKey="it" />
  </Layout>
)

export default IndexPageIt
